
var queryForm = function(settings){
    var reset = settings && settings.reset ? settings.reset : false;
    var self = window.location.toString();
    var querystring = self.split("?");
    if (querystring.length > 1) {
        var pairs = querystring[1].split("&");
        for (i in pairs) {
            var keyval = pairs[i].split("=");
            if (reset || sessionStorage.getItem(keyval[0]) === null) {
                sessionStorage.setItem(keyval[0], decodeURIComponent(keyval[1]));
            }
        }
    }
    /*
    var hiddenFields = document.querySelectorAll("input[type=hidden], input[type=text]");
    for (var i=0; i<hiddenFields.length; i++) {
        var param = sessionStorage.getItem(hiddenFields[i].name);
        if (param) document.getElementsByName(hiddenFields[i].name)[0].value = param;
    }
     */

    var csrf = document.querySelector('meta[name="csrf-token"]').content;
    var gclid = sessionStorage.getItem("gclid");

    $.ajax({
        type: 'POST',
        url:'/trackvisit',
        data:{
            _token:csrf,
            gclid:gclid,
        },
        beforeSend: function (xhr) {
            var token = $('meta[name="csrf-token"]').attr('content');
            if (token) {
                return xhr.setRequestHeader('X-CSRF-TOKEN', token);
            }
        },
        complete: function() {
            console.log('Ajax request completed');
        },
        success: function (data) {
           console.log(data);
        },
        error: function (xhr, ajaxOptions, thrownError) {
            console.log('server error', thrownError);
        }
    });


}

setTimeout(function(){
    queryForm({reset: true});}, 1000);
